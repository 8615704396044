import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { updateSolicitud } from "../../services/updateSolicitud";
import { notify } from "../../utils/utils";

export default function DialogRechazoCont({
  open,
  setOpen,
  selected,
  handleRefresh,
  setSelected,
}) {
  const [mensaje, setMensaje] = useState("");

  const handleSubmit = async () => {
    const param = { MensajeRechazo: mensaje };
    const res = await updateSolicitud(
      selected.map((s) => s.id),
      "Rechazado",
      param
    );
    if (res.exitoso) {
      setOpen(false);
      notify("info", "Contenedores rechazados");
      handleRefresh();
    } else {
      notify("warning", res.error);
    }
  };

  return (
    <Dialog maxWidth="sm" onClose={() => setOpen(false)} open={open}>
      <DialogTitle>Rechazo de contenedores</DialogTitle>
      <DialogContent dividers>
        <Typography mb={2} variant="subtitle2">
          Ingresa el motivo por el cual se rechazan los contenedores <br />
          seleccionados.
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          onChange={(e) => setMensaje(e.target.value)}
        ></TextField>
      </DialogContent>

      <DialogActions>
        <Button autoFocus>Cancelar</Button>
        <Button onClick={handleSubmit}>Aceptar</Button>
      </DialogActions>
    </Dialog>
  );
}
