const Colors = {
  white: "white",
  lightgrey: "lightgrey",
  lightgrey1: "#efefef",
  lightgrey2: "#bbafaf",
  lightgrey3: "#f8fafb",
  grey: "#656263",
  darkgrey: "#343a40",
};

export default Colors;
