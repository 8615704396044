import cryptoJs from "crypto-js";
import { toast } from "react-toastify";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Badge,
} from "@mui/material";
import CaractContenedor from "../components/utils/CaractContenedor";

const notify = (status, content, autoClose = 5000, hideProgressBar = false) => {
  const toastConfig = {
    position: "top-center",
    autoClose: autoClose,
    hideProgressBar: hideProgressBar,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  // Toggle notification
  if (status === "success") {
    toast.success(content, toastConfig);
  } else if (status === "info") {
    toast.info(content, toastConfig);
  } else if (status === "warning") {
    toast.warn(content, toastConfig);
  } else if (status === "error") {
    toast.error(content, toastConfig);
  } else {
    toast(content, toastConfig);
  }

  // Clear queue
  toast.clearWaitingQueue();
};

const encripta = (data) => {
  return cryptoJs.AES.encrypt(data, process.env.REACT_APP_ORG);
};

const decripta = (data, explicit = false) => {
  const salted = cryptoJs.AES.decrypt(data, process.env.REACT_APP_ORG);

  if (explicit) return salted.toString(cryptoJs.enc.Utf8);
  else return salted.toString();
};

const columnasIconos = () => {
  return [
    {
      name: (
        <Tooltip title="Peligroso">
          <ReportGmailerrorredIcon />
        </Tooltip>
      ),
      width: "80px",
      cell: (row) => (row.EsPeligroso ? <CheckIcon color="success" /> : <></>),
    },
    {
      name: (
        <Tooltip title="Refeer">
          <AcUnitIcon />
        </Tooltip>
      ),
      width: "80px",
      cell: (row) => (row.EsRefeer ? <CheckIcon color="success" /> : <></>),
    },
    {
      name: (
        <Tooltip title="Sobredimensionado">
          <SettingsOverscanIcon />
        </Tooltip>
      ),
      width: "80px",
      cell: (row) =>
        row.EsSobredimension ? <CheckIcon color="success" /> : <></>,
    },
  ];
};

const ProgresoContenedor = ({ porcentaje }) => {
  return (
    <Grid spacing={1} container>
      <Grid xs item>
        <LinearProgress
          variant="determinate"
          color={
            porcentaje < 40 ? "error" : porcentaje < 80 ? "warning" : "success"
          }
          valueBuffer={porcentaje || 0}
          value={porcentaje}
        />
      </Grid>
    </Grid>
  );
};

const columnasTracking = (device, seleccionaMapa) => {
  var columnas = [
    { name: "Contenedor", selector: (row) => row.Contenedor, sortable: true },
  ];

  if (device > 1) {
    columnas = [
      ...columnas,
      ...[
        { name: "BL", selector: (row) => row.Bl, sortable: true },
        {
          name: "",
          width: "100px",
          cell: (row) => <CaractContenedor cont={row} />,
        },
      ],
    ];
  }

  columnas.push({
    name: "",
    width: "100px",
    cell: (row) => (
      <Button disabled={row.Cancelado} onClick={() => seleccionaMapa(row)}>
        Mapa
      </Button>
    ),
  });

  return columnas;
};

const columnasPresolicitud = (
  setSeleccion = null,
  columnasExtra = [],
  alertaError = false
) => {
  var columnas = [
    { name: "Contenedor", selector: (row) => row.Contenedor, sortable: true },
    { name: "BL", selector: (row) => row.Bl, sortable: true },
    { name: "Tipo", selector: (row) => row.Tipo, sortable: true },
    { name: "Buque", selector: (row) => row.Buque, sortable: true },
    { name: "Viaje", selector: (row) => row.Viaje, sortable: true },
    { name: "ETA", selector: (row) => row.Eta },
    ...columnasIconos(),
  ];

  if (columnasExtra.length > 0) columnas = [...columnas, ...columnasExtra];

  if (setSeleccion)
    columnas.push({
      name: "",
      width: "90px",
      cell: (row) =>
        alertaError && row.Descripcion ? (
          <Badge
            badgeContent={""}
            overlap="circular"
            color="warning"
            variant="dot"
          >
            <IconButton onClick={() => setSeleccion(row)}>
              <MoreVertIcon />
            </IconButton>
          </Badge>
        ) : (
          <IconButton onClick={() => setSeleccion(row)}>
            <MoreVertIcon />
          </IconButton>
        ),
    });
  return columnas;
};

const columnasPresolicitudMobile = (
  setSeleccion = null,
  columnasExtra = [],
  device = null
) => {
  var columnas = [
    {
      name: "Contenedor",
      selector: (row) => row.Contenedor,
      sortable: true,
    },
    { name: "BL", selector: (row) => row.Bl, sortable: true, hide: "sm" },
  ];

  // Mostrar responsive
  if (device) {
    columnas = device < 3 ? columnas : [...columnas, ...columnasIconos()];
  }

  if (columnasExtra.length > 0) columnas = [...columnas, ...columnasExtra];

  if (setSeleccion)
    columnas.push({
      name: "",
      width: "90px",
      cell: (row) => (
        <IconButton onClick={() => setSeleccion(row)}>
          <MoreVertIcon />
        </IconButton>
      ),
    });
  return columnas;
};

const tablaSeleccionCondicion = (seleccion) => {
  return [
    {
      when: (row) => seleccion.includes(row.id),
      style: {
        backgroundColor: "#e4f2fc",
      },
    },
  ];
};

const tablaSeleccionCondicionObject = (seleccion) => {
  return [
    {
      when: (row) => seleccion.filter((s) => s.id === row.id).length > 0,
      style: {
        backgroundColor: "#e4f2fc",
      },
    },
  ];
};

export {
  notify,
  encripta,
  decripta,
  ProgresoContenedor,
  columnasTracking,
  columnasPresolicitud,
  columnasPresolicitudMobile,
  tablaSeleccionCondicion,
  tablaSeleccionCondicionObject,
};
