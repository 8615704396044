import { obtieneCache } from "../utils/cache/sessionStorage";
import urls from "../utils/constants/urls";
import { peticion } from "./peticionHttp";

async function updateSolicitud(seleccion, estatus, params = null) {
  const userdata = obtieneCache("userdata");

  var body = {
    Seleccion: seleccion,
    Estatus: estatus,
    Usuario: userdata.usuario,
  };

  if (params) body = { ...body, ...params };

  const response = await peticion(urls.updateSolicitud, "POST", body);
  return response;
}

export { updateSolicitud };
