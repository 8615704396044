import React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Fonts } from "../../styles";
import NearMeDisabledIcon from "@mui/icons-material/NearMeDisabled";

export default function TimelineStepComponent({
  device,
  etapas,
  ultimaEtapa,
  orientation,
}) {
  return (
    <div>
      <Box
        sx={{
          width: "100%",
          // height: device < 4 ? 1 : 0,
          paddingTop: 2,
          paddingBottom: 2,
          // overflowY: device < 4 ? "scroll" : "",
          overflowX: device < 4 ? "scroll" : "",
        }}
      >
        {etapas.length > 0 ? (
          <Stepper
            activeStep={ultimaEtapa - 1}
            alternativeLabel={orientation === "horizontal"}
            orientation={orientation}
            sx={{
              height: "max-content",
            }}
          >
            {etapas &&
              etapas.map((obj, i) => (
                <Step
                  key={i + obj.name}
                  sx={{
                    zIndex: 11,
                    // height: 140,
                    paddingLeft: 2,
                    paddingRight: 2,
                    backgroundColor: "white",
                  }}
                >
                  <StepLabel>
                    <Typography
                      fontSize={device < 4 ? Fonts.sizes.xs : Fonts.sizes.s}
                    >
                      {obj.date || (i === 2 || i === 3 ? "N/A" : "-")}
                    </Typography>
                    <Typography variant="caption">{obj.name}</Typography>
                  </StepLabel>
                </Step>
              ))}
          </Stepper>
        ) : (
          <Alert
            severity="warning"
            sx={{
              marginLeft: 2,
              marginRight: 2,
            }}
            icon={<NearMeDisabledIcon />}
          >
            <AlertTitle>
              <strong>Ups..</strong>
            </AlertTitle>
            No se encontró información relacionada, favor de validar el
            contenedor y BL
          </Alert>
        )}
      </Box>
    </div>
  );
}
