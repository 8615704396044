import { Box, Button, Divider, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import DropzoneUploader from "./DropzoneUploader";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import urls from "../../utils/constants/urls";
import { peticionArchivo } from "../../services/peticionHttp";

export default function SubeExcelContenedores({ device, open, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: device < 3 ? "80%" : "40%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} px={2} py={5}>
        <Box p={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <LooksOneIcon />
            <Typography
              id="modal-modal-title"
              variant="button"
              fontSize={device < 3 ? 14 : 20}
              component="h1"
            >
              Descarga la plantilla
            </Typography>
          </Stack>
          <Typography
            id="modal-modal-description"
            variant="subtitle"
            sx={{ mt: 2 }}
          ></Typography>
          <Button
            fullWidth
            variant="outlined"
            onClick={async () =>
              await peticionArchivo(
                urls.uploadContainers,
                "GET",
                "Logipuerto.xlsx"
              )
            }
            sx={{ marginTop: 2, marginBottom: 2 }}
          >
            Descargar
          </Button>
        </Box>
        <Divider />
        <Box p={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <LooksTwoIcon />
            <Typography
              id="modal-modal-title"
              variant="button"
              fontSize={device < 3 ? 14 : 20}
              component="h1"
            >
              Sube tu archivo
            </Typography>
          </Stack>
          <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <DropzoneUploader handleClose={handleClose} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
