import urls from "../utils/constants/urls";
import { peticion } from "./peticionHttp";
import moment from "moment";

async function updateContenedor(datos) {
  var cnt = datos;
  cnt.Eta = moment(cnt.Eta).format("YYYY-MM-DD");

  const res = await peticion(urls.updateContenedor, "POST", datos);
  return res;
}

export { updateContenedor };
