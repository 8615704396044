import urls from "../utils/constants/urls";
import { peticion } from "./peticionHttp";

async function updateCliente(idCliente, hub) {
  const body = {
    Id: idCliente,
    Hub: hub,
  };
  const res = await peticion(urls.updateCliente, "POST", body);
  return res;
}

export { updateCliente };
