import React from "react";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/es";
import { Formik } from "formik";
import * as Yup from "yup";

import { updateContenedor } from "../../services/updateContenedor";
import { notify } from "../../utils/utils";
import moment from "moment";

export default function FormContenedor({ obj, closeDrawer, clase, esAgente }) {
  const ValidacionCont = Yup.object().shape({
    Bl: Yup.string()
      .required("Requerido")
      .max(250, "El BL sobrepasa el tamaño permitido"),
    Tipo: Yup.string()
      .required("Requerido")
      .min(4, "Debe tener 4 caracteres")
      .max(4, "Debe tener 4 caracteres"),
    Buque: Yup.string()
      .required("Requerido")
      .max(250, "El buque sobrepasa el tamaño permitido"),
    Viaje: Yup.string()
      .required("Requerido")
      .max(100, "El viaje sobrepasa el tamaño permitido"),
    Eta: Yup.string().required("Seleccione una fecha"),
  });

  const datosContenedor = (c) => {
    var eta = moment(c.Eta, "DD/MM/YYYY");

    return {
      Clase: clase,
      Contenedor: c.Contenedor,
      Bl: c.Bl,
      Tipo: c.Tipo,
      Buque: c.Buque,
      Viaje: c.Viaje,
      Eta: c.Eta !== null ? eta : "",
      EsPeligroso: c.EsPeligroso,
      EsRefeer: c.EsRefeer,
      EsSobredimension: c.EsSobredimension,
    };
  };

  return (
    <Formik
      initialValues={datosContenedor(obj)}
      validationSchema={ValidacionCont}
      validateOnMount
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const res = await updateContenedor(values);

        if (res.exitoso) {
          closeDrawer(res.contenedor);
          notify("success", "Registro actualizado");
        } else notify("warning", "Ups.. hubo un error.");

        setSubmitting(false);
      }}
    >
      {({
        values,
        setFieldValue,
        handleChange,
        handleSubmit,
        isSubmitting,
        errors,
      }) => (
        <Stack mt={4} mx={3} spacing={2}>
          <TextField
            name="Bl"
            label="Bl"
            value={values.Bl}
            onChange={handleChange}
            error={errors.Bl}
            helperText={errors.Bl}
          ></TextField>
          <TextField
            name="Tipo"
            label="Tipo"
            value={values.Tipo}
            onChange={handleChange}
            error={errors.Tipo}
            helperText={errors.Tipo}
          ></TextField>
          <TextField
            name="Buque"
            label="Buque"
            value={values.Buque}
            onChange={handleChange}
            error={errors.Buque}
            helperText={errors.Buque}
          ></TextField>
          <TextField
            name="Viaje"
            label="Viaje"
            value={values.Viaje}
            onChange={handleChange}
            error={errors.Viaje}
            helperText={errors.Viaje}
          ></TextField>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="ETA"
              inputFormat="DD/MM/YYYY"
              disableMaskedInput
              value={values.Eta}
              onChange={(e) => {
                setFieldValue("Eta", e);
              }}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
            />
            {errors.Eta && (
              <div style={{ marginTop: 0 }}>
                <Typography variant="caption" color="red">
                  {errors.Eta}
                </Typography>
              </div>
            )}
          </LocalizationProvider>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.EsPeligroso}
                  name="EsPeligroso"
                  onChange={handleChange}
                />
              }
              label="Es peligroso"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.EsRefeer}
                  name="EsRefeer"
                  onChange={handleChange}
                />
              }
              label="Es refeer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.EsSobredimension}
                  name="EsSobredimension"
                  onChange={handleChange}
                />
              }
              label="Es sobredimensionado"
            />
          </FormGroup>
          {esAgente && obj.Descripcion && (
            <FormGroup>
              <Alert severity="warning">{obj.Descripcion}</Alert>
            </FormGroup>
          )}

          <Stack spacing={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={Object.keys(errors).length > 0 || isSubmitting}
              onClick={handleSubmit}
            >
              Guardar cambios
            </Button>
            <Button fullWidth variant="outlined" onClick={closeDrawer}>
              Cerrar
            </Button>
          </Stack>
        </Stack>
      )}
    </Formik>
  );
}
