const dominio = "http://tracking.logipuerto.mx:82";
// const dominio = "http://cmv41016:8082";
// const dominio = "http://localhost:8000";
// const dominio = "http://172.20.79.15:8000";
const url = dominio + "/api/v1";

const urls = {
  login: `${url}/auth/`,
  getTracking: `${url}/tracking/`,
  getTrackingInfo: `${url}/tracking/info`,
  getHistorico: `${url}/tracking/historico/`,
  getHubs: `${url}/tracking/hubs/`,
  getPresolicitudes: `${url}/solicitud/presolicitudes`,
  getPresolicitudesHistoria: `${url}/solicitud/presolicitudes/historia`,
  getSolicitudes: `${url}/solicitud/pendientes`,
  getSolicitudesRechazadas: `${url}/solicitud/rechazadas`,
  getTrackingSolicitudes: `${url}/solicitud/tracking`,
  uploadContainers: `${url}/solicitud/descarga-formato`,
  updateSolicitud: `${url}/solicitud/actualizar`,
  updateCliente: `${url}/solicitud/cliente/actualizar`,
  updateContenedor: `${url}/solicitud/contenedor/actualizar`,
  updateOrden: `${url}/solicitud/orden/actualizar`,
};

export default urls;
