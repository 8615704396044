import React, { useState } from "react";
import { Formik } from "formik";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import moment from "moment";
import "moment/locale/es";

import { updateOrden } from "../../services/updateOrden";
import { notify } from "../../utils/utils";

export default function FormOrdenServicio({
  device,
  obj,
  setObj,
  datos,
  setDatos,
  setAbreOrden,
}) {
  const [cargando, setCargando] = useState(false);
  const [abre, setAbre] = useState(false);

  const envioForm = async (values, accion) => {
    setCargando(true);
    const res = await updateOrden(values, accion);
    if (res.exitoso) {
      setDatos(datos.map((obj) => (obj.id === values.id ? res.obj : obj)));
      setObj(res.obj);
      notify(res.exitoso_liftit ? "success" : "warning", res.msg);
      if (res.exitoso_liftit) {
        setAbre(false);
        setAbreOrden(false);
      }
    } else {
      notify("warning", "Hubo un error...");
    }
    setCargando(false);
  };

  return (
    <Formik
      initialValues={{
        id: obj.id,
        Contenedor: obj.Contenedor,
        Bl: obj.Bl,
        FechaInicioOrden: obj.FechaInicioOrden
          ? moment(obj.FechaInicioOrden, "DD/MM/YYYY HH:mm:SS")
          : "",
        FechaFinOrden: obj.FechaFinOrden
          ? moment(obj.FechaFinOrden, "DD/MM/YYYY HH:mm:SS")
          : "",
        Descripcion: obj.Descripcion,
      }}
      onSubmit={async (values) => {
        envioForm(values, "Editar");
      }}
      enableReinitialize
    >
      {({ values, handleChange, setFieldValue, errors, handleSubmit }) => (
        <Grid container p={5} spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row">
              <ChevronRightIcon fontSize="small" />
              <Typography variant="subtitle2">Detalles del servicio</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="Contenedor"
              label="Contenedor"
              fullWidth
              variant="standard"
              size={device < 4 ? "small" : "normal"}
              value={values.Contenedor}
              onChange={handleChange}
              error={errors.Contenedor}
              helperText={errors.Contenedor}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="Bl"
              label="Bl"
              fullWidth
              variant="standard"
              size={device < 4 ? "small" : "normal"}
              value={values.Bl}
              onChange={handleChange}
              error={errors.Bl}
              helperText={errors.Bl}
            ></TextField>
          </Grid>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid item xs={12} md={6}>
              <DateTimePicker
                label="Fecha de inicio"
                inputFormat="DD/MM/YYYY HH:mm:SS"
                disableMaskedInput
                value={values.FechaInicioOrden}
                onChange={(e) => {
                  setFieldValue("FechaInicioOrden", e);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      fullWidth
                      variant="standard"
                      size={device < 4 ? "small" : "normal"}
                      {...params}
                    />
                  );
                }}
              />
              {errors.FechaInicioOrden && (
                <div style={{ marginTop: 0 }}>
                  <Typography variant="caption" color="red">
                    {errors.FechaInicioOrden}
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimePicker
                label="Fecha de finalizado"
                inputFormat="DD/MM/YYYY HH:mm:SS"
                disableMaskedInput
                value={values.FechaFinOrden}
                onChange={(e) => {
                  setFieldValue("FechaFinOrden", e);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      fullWidth
                      variant="standard"
                      size={device < 4 ? "small" : "normal"}
                      {...params}
                    />
                  );
                }}
              />
              {errors.FechaFinOrden && (
                <div style={{ marginTop: 0 }}>
                  <Typography variant="caption" color="red">
                    {errors.FechaFinOrden}
                  </Typography>
                </div>
              )}
            </Grid>
          </LocalizationProvider>

          {values.Descripcion && (
            <Grid item xs={12}>
              <Alert severity="warning">
                Error liftit: {values.Descripcion}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid
              container
              mt={2}
              textAlign="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={12} lg={6}>
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  disabled={cargando}
                  onClick={() => setAbre(true)}
                >
                  Cancelar servicio
                </Button>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={cargando}
                  onClick={handleSubmit}
                >
                  Guardar cambios
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Dialog open={abre}>
            <DialogTitle>¿Seguro que desea cancelar el servicio?</DialogTitle>
            <DialogActions>
              <Button autoFocus onClick={() => setAbre(false)}>
                Cancelar
              </Button>
              <Button onClick={() => envioForm(values, "Eliminar")}>Ok</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Formik>
  );
}
