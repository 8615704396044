import { Drawer, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getSolicitudes } from "../services/getSolicitudes";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import DrawerCliente from "./DrawerCliente";
import SolicitudesPendientes from "./SolicitudesPendientes";
import SolicitudesRechazadas from "./SolicitudesRechazadas";
import FormContenedor from "./utils/FormContenedor";
import { getSolicitudesRechazo } from "../services/getSolicitudesRechazo";
import ChipTitleComponent from "./utils/ChipTitleComponent";
import { obtieneCache } from "../utils/cache/sessionStorage";

export default function Solicitudes({ device }) {
  const [data, setData] = useState([]);
  const [dataRechazo, setDataRechazo] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedCont, setSelectedCont] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [cargandoPendientes, setCargandoPendientes] = useState(false);
  const [cargandoRechazados, setCargandoRechazados] = useState(false);
  const [clearRows, setClearRows] = useState(false);

  // Paginacion
  const [total, setTotal] = useState(0);
  const [totalRechazo, setTotalRechazo] = useState(0);
  const esAgente = obtieneCache("userdata").agente !== undefined;

  const handleRefresh = () => {
    setSelected([]);
    setClearRows(!clearRows);
    setRefresh(!refresh);
  };

  const closeContDrawer = (contActualizado = null) => {
    setSelectedCont(null);
    if (contActualizado) {
      // Actualiza contenedor de la tabla para simular que se actualiza la info
      const updated = data.map((c) => {
        if (c.Contenedor === contActualizado.Contenedor) return contActualizado;
        else return c;
      });
      setData(updated);
    }
  };

  const consultaRechazo = async (_pag, filtro = "") => {
    setCargandoRechazados(true);
    const res = await getSolicitudesRechazo(_pag, filtro);
    setDataRechazo(res.results);
    setTotalRechazo(res.count);
    setCargandoRechazados(false);
  };

  const consulta = async (_pag, filtro = "") => {
    setCargandoPendientes(true);
    const res = await getSolicitudes(_pag, filtro);
    setData(res.results);
    setTotal(res.count);
    setCargandoPendientes(false);
  };

  useEffect(() => {
    (async () => {
      consulta(1);
      consultaRechazo(1);
    })();
  }, [refresh]);

  return (
    <>
      {device < 2 ? (
        <ChipTitleComponent
          label="Solicitudes de transporte"
          icon={<DepartureBoardIcon />}
        />
      ) : (
        <Typography variant="h5">
          Solicitudes de transporte <DepartureBoardIcon color="warning" />
        </Typography>
      )}

      <SolicitudesPendientes
        device={device}
        data={data}
        handleRefresh={handleRefresh}
        setSelectedClient={setSelectedClient}
        setSelectedCont={setSelectedCont}
        total={total}
        consulta={consulta}
        cargando={cargandoPendientes}
        selected={selected}
        setSelected={setSelected}
        clearRows={clearRows}
        esAgente={esAgente}
      />

      <SolicitudesRechazadas
        device={device}
        data={dataRechazo}
        handleRefresh={handleRefresh}
        setSelectedClient={setSelectedClient}
        total={totalRechazo}
        consulta={consultaRechazo}
        cargando={cargandoRechazados}
        esAgente={esAgente}
      />

      {selectedClient !== null && (
        <DrawerCliente
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          handleRefresh={handleRefresh}
        />
      )}

      <Drawer
        anchor="right"
        onClose={closeContDrawer}
        PaperProps={{
          sx: { width: device < 2 ? "90%" : device < 4 ? "40%" : "20%" },
        }}
        open={selectedCont !== null}
      >
        <Toolbar />
        {selectedCont && (
          <>
            <Typography variant="caption" color="darkgrey" mx={3} mt={4}>
              Datos del contenedor
            </Typography>
            <Typography variant="h4" mx={3} mb={1}>
              {selectedCont.Contenedor}
            </Typography>

            <FormContenedor
              obj={selectedCont}
              closeDrawer={closeContDrawer}
              clase="sol"
              esAgente={esAgente}
            />
          </>
        )}
      </Drawer>
    </>
  );
}
