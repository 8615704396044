import moment from "moment";
import urls from "../utils/constants/urls";
import { peticion } from "./peticionHttp";

async function updateOrden(datos, accion) {
  var body = {
    ...datos,
    Accion: accion,
  };

  body.FechaInicioOrden = moment(body.FechaInicioOrden).format(
    "YYYY-MM-DD HH:mm:SS"
  );
  body.FechaFinOrden = moment(body.FechaFinOrden).format("YYYY-MM-DD HH:mm:SS");

  const res = await peticion(urls.updateOrden, "POST", body);
  return res;
}

export { updateOrden };
