import React from "react";
import { Chip, Stack } from "@mui/material";

export default function ChipTitleComponent({
  label,
  icon,
  size = "small",
  color = "warning",
  variant = "outlined",
}) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {icon}
      <Chip label={label} size={size} color={color} variant={variant} />
    </Stack>
  );
}
