import React, { useState } from "react";
import { Alert, Button, CircularProgress, Grid, Stack } from "@mui/material";
import Dropzone from "react-simple-dropzone/dist";
import { obtieneCache } from "../../utils/cache/sessionStorage";
import { notify } from "../../utils/utils";
import urls from "../../utils/constants/urls";
import ClearIcon from "@mui/icons-material/Clear";

const DropzoneUploader = ({ handleClose }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setPending(true);
    const userdata = obtieneCache("userdata");

    const formData = new FormData();
    formData.append("file", file);

    const request = await fetch(urls.uploadContainers, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userdata.token,
      },
      body: formData,
    });

    const response = await request.json();
    setPending(false);

    if (response.error) {
      notify("warning", "Hubo un error...");
      setError(response.msg);
    } else {
      notify(
        "success",
        `Se cargaron ${response.total} contenedores exitosamente`
      );
      handleClose(true);
    }
  }

  return (
    <form>
      <Stack spacing={2}>
        <Dropzone
          onSuccessBlob={(file) => {
            setError("");
            setFile(file);
          }}
          accept="application/*"
          validTypes={[
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ]}
          displayText="Da click o arrastra tu archivo aquí."
          onError={(e) => {
            setFile(null);

            if (e === "Invalid File Type")
              setError("Ingrese solo un archivo de extensión .xlsx");
            else setError(e);
          }}
          preview={false}
        />
        {file !== null && (
          <Alert severity="success">
            <Stack direction="row" spacing={1}>
              <div>{file.name}</div>
              <ClearIcon
                color="info"
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setFile(null);
                  setError("");
                }}
              />
            </Stack>
          </Alert>
        )}
        {error && <Alert severity="warning">{error}</Alert>}
        <Grid container>
          <Grid item xs={12} md={6}>
            <Button fullWidth onClick={() => handleClose()}>
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="contained"
              disabled={file === null || pending}
              onClick={handleSubmit}
            >
              {pending && <CircularProgress size={20} />} &nbsp; Enviar
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
};

export default DropzoneUploader;
