import { Drawer, Toolbar, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";

import SubeExcelContenedores from "./utils/SubeExcelContenedores";
import PresolicitudesPendientes from "./PresolicitudesPendientes";
import PresolicitudesMovimientos from "./PresolicitudesMovimientos";
import FormContenedor from "./utils/FormContenedor";
import { getPresolicitudes } from "../services/getPresolicitudes";
import { getPresolicitudesHistoria } from "../services/getPresolicitudesHistoria";
import ChipTitleComponent from "./utils/ChipTitleComponent";

export default function Presolicitudes({ device }) {
  const [data, setData] = useState([]);
  const [dataProceso, setDataProceso] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedCont, setSelectedCont] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [cargandoPendientes, setCargandoPendientes] = useState(false);
  const [cargandoMovimientos, setCargandoMovimientos] = useState(false);
  // paginacion
  const [total, setTotal] = useState(0);
  const [totalProceso, setTotalProceso] = useState(0);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleClose = (_refresh = false) => {
    setOpenCreate(false);
    if (_refresh) {
      handleRefresh();
    }
  };

  const closeDrawer = (contActualizado = null) => {
    setSelectedCont(null);
    if (contActualizado) {
      // Actualiza contenedor de la tabla para simular que se actualiza la info
      setData(
        data.map((c) => {
          if (c.Contenedor === contActualizado.Contenedor)
            return contActualizado;
          else return c;
        })
      );
    }
  };

  const consultaProceso = async (_pag, filtro = "") => {
    setCargandoMovimientos(true);
    const res = await getPresolicitudesHistoria(_pag, filtro);
    setDataProceso(res.results);
    setTotalProceso(res.count);
    setCargandoMovimientos(false);
  };

  const consulta = async (_pag, filtro = "") => {
    setCargandoPendientes(true);
    const res = await getPresolicitudes(_pag, filtro);
    setData(res.results);
    setTotal(res.count);
    setCargandoPendientes(false);
  };

  useEffect(() => {
    (async () => {
      consulta(1);
      consultaProceso(1);
    })();
  }, [refresh]);

  return (
    <>
      {device < 2 ? (
        <ChipTitleComponent
          label="Pendientes de transporte"
          icon={<DepartureBoardIcon />}
        />
      ) : (
        <Typography variant="h5">
          Pendientes de transporte{" "}
          <DepartureBoardIcon color="warning" fontSize="medium" /> &nbsp;
        </Typography>
      )}

      <PresolicitudesPendientes
        device={device}
        data={data}
        openCreate={openCreate}
        setOpenCreate={setOpenCreate}
        setSelectedCont={setSelectedCont}
        handleRefresh={handleRefresh}
        consulta={consulta}
        total={total}
        cargando={cargandoPendientes}
        selected={selected}
        setSelected={setSelected}
      />

      {dataProceso.length > 0 && (
        <PresolicitudesMovimientos
          device={device}
          data={dataProceso}
          consulta={consultaProceso}
          total={totalProceso}
          cargando={cargandoMovimientos}
        />
      )}

      <SubeExcelContenedores
        device={device}
        open={openCreate}
        handleClose={handleClose}
      />

      <Drawer
        anchor="right"
        onClose={closeDrawer}
        PaperProps={{
          sx: { width: device < 2 ? "90%" : device < 4 ? "40%" : "20%" },
        }}
        open={selectedCont !== null}
      >
        <Toolbar />
        {selectedCont && (
          <>
            <Typography variant="caption" color="darkgrey" mx={3} mt={4}>
              Datos del contenedor
            </Typography>
            <Typography variant="h4" mx={3} mb={1}>
              {selectedCont.Contenedor}
            </Typography>

            <FormContenedor
              obj={selectedCont}
              closeDrawer={closeDrawer}
              clase="pre"
            />
          </>
        )}
      </Drawer>
    </>
  );
}
