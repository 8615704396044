import { ClickAwayListener, IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { useState } from "react";

export default function CaractContenedor({ cont }) {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {cont.EsPeligroso && (
        <Tooltip title="Peligroso">
          <ReportGmailerrorredIcon />
        </Tooltip>
      )}
      {cont.EsRefeer && (
        <Tooltip title="Refeer">
          <AcUnitIcon />
        </Tooltip>
      )}
      {cont.EsSobredimension && (
        <Tooltip title="Sobredimensionado">
          <SettingsOverscanIcon />
        </Tooltip>
      )}
    </Stack>
  );
}
