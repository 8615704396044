import {
  Autocomplete,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import urls from "../utils/constants/urls";
import { obtieneCache } from "../utils/cache/sessionStorage";
import { getHubs } from "../services/getHubs";
import { peticion } from "../services/peticionHttp";
import { notify } from "../utils/utils";
import { Container } from "@mui/system";
import ChipTitleComponent from "./utils/ChipTitleComponent";

export default function Historico({ device, submodulo }) {
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [abre, setAbre] = useState(false);

  const [hubs, setHubs] = useState([]);
  const [hubId, setHubId] = useState("");
  const userdata = obtieneCache("userdata");
  const esAgente = userdata.agente !== undefined;
  const cargando = abre && hubs.length === 0;

  const consultaDashboards = async (_hub) => {
    const res = await peticion(urls.getHistorico + _hub + "/" + submodulo);

    if (res.exitoso) {
      setDashboardUrl(res.url);
    } else {
      notify(
        "warning",
        esAgente
          ? res.error
          : "Hubo un error... Favor de contactar a su agente de logipuerto"
      );
    }
  };

  const obtenerHubIdUsuario = async () => {
    var _hub = "";

    // Si es agente, pedir hubId, sino, tomar el hub id guardado
    if (esAgente) {
      if (hubs.length === 0) {
        const consulta = await getHubs();
        if (consulta.exitoso) setHubs(consulta.hubs);
      }

      if (hubId !== "") _hub = hubId;
    } else {
      _hub = userdata.hub;
    }

    return _hub;
  };

  useEffect(() => {
    (async () => {
      var _hub = await obtenerHubIdUsuario();

      if (_hub !== "") {
        consultaDashboards(_hub);
      }
    })();
  }, [submodulo]);

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        {device < 2 ? (
          <ChipTitleComponent label="Dashboard" icon={<EqualizerIcon />} />
        ) : (
          <Typography variant="h4" mb={2}>
            Dashboard
          </Typography>
        )}
      </div>

      {esAgente && (
        <div>
          <Tooltip
            title="Selecciona un hub id"
            placement="bottom"
            arrow
            open={hubId === "" && device > 3}
          >
            <Stack
              direction={device < 2 ? "column" : "row"}
              justifyContent="center"
              mb={2}
              spacing={1}
            >
              <Autocomplete
                id="asynchronous-demo"
                sx={{ width: device < 3 ? "100%" : "50%" }}
                open={abre}
                onOpen={() => {
                  setAbre(true);
                }}
                onClose={() => {
                  setAbre(false);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.Nombre === value.Nombre
                }
                getOptionLabel={(option) => option.Hub + " " + option.Nombre}
                options={hubs}
                loading={cargando}
                onChange={(_, v) => setHubId(v === null ? "" : v.Hub)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Hub Id"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {cargando ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              <Button
                variant="contained"
                disabled={hubId === ""}
                onClick={() => consultaDashboards(hubId)}
              >
                <SearchIcon />
              </Button>
            </Stack>
          </Tooltip>

          {dashboardUrl === null && (
            <Container
              maxWidth="xl"
              sx={{
                marginTop: 5,
                backgroundColor: "white",
                borderRadius: 2,
                marginBottom: 3,
                padding: 5,
                textAlign: "center",
                border: 1,
                borderColor: "lightgray",
              }}
            >
              <QueryStatsIcon style={{ fontSize: "70px", color: "gray" }} />
              <br />
              <Typography variant="body" color="">
                Busca un hub para visualizar los dashboards
              </Typography>
            </Container>
          )}
        </div>
      )}

      {dashboardUrl && (
        <>
          <iframe
            style={{
              width: "100%",
              marginTop: 5,
              height: "430vh",
            }}
            title={submodulo}
            src={dashboardUrl}
            frameBorder="0"
            width="800"
            height="600"
            allowtransparency="true"
          ></iframe>
        </>
      )}
    </>
  );
}
