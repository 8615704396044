import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import NoTransferIcon from "@mui/icons-material/NoTransfer";
import {
  columnasPresolicitud,
  columnasPresolicitudMobile,
  notify,
} from "../utils/utils";
import SearchIcon from "@mui/icons-material/Search";
import ChipTitleComponent from "./utils/ChipTitleComponent";
import { updateSolicitud } from "../services/updateSolicitud";

export default function SolicitudesRechazadas({
  device,
  data,
  setSelectedClient,
  total,
  consulta,
  cargando,
  esAgente,
  handleRefresh,
}) {
  const [abre, setAbre] = useState(false);
  const [seleccion, setSeleccion] = useState(null);
  const [filtro, setFiltro] = useState("");
  const [pending, setPending] = useState(false);

  const columns = [
    ...(device < 4 ? columnasPresolicitudMobile() : columnasPresolicitud()),
    {
      name: "Cliente",
      sortable: true,
      hide: "sm",
      selector: (row) => (
        <Tooltip
          title="Da clic para agregar hub id"
          style={{ cursor: "pointer" }}
        >
          <Typography
            variant="caption"
            color="#311b92"
            onClick={() => setSelectedClient(row.Cliente)}
          >
            {row.Cliente.Nombre}
          </Typography>
        </Tooltip>
      ),
    },
    {
      name: "Fecha solicitud",
      cell: (row) => (
        <Typography variant="caption" color="grey">
          {row.Solicitado}
        </Typography>
      ),
      sortable: false,
    },
  ];

  const handleRestauraSolicitud = async () => {
    setPending(true);
    const res = await updateSolicitud([seleccion.id], "Solicitud");

    setPending(false);
    if (res.exitoso) {
      notify("success", "Solicitud restaurada");
      setAbre(false);
      handleRefresh();
    } else {
      notify("error", res.msg);
    }
  };

  const DetalleMovimiento = ({ data }) => {
    return (
      <List dense>
        <ListItem>
          <ListItemText
            primary="Motivo del rechazo:"
            secondary={data.MensajeRechazo || ""}
          />
        </ListItem>
        {esAgente && (
          <ListItem>
            <Button
              variant="contained"
              color="warning"
              disabled={pending}
              onClick={() => {
                setSeleccion(data);
                setAbre(true);
              }}
            >
              Restaurar
            </Button>
          </ListItem>
        )}
      </List>
    );
  };

  return (
    <>
      <Grid container alignItems="center" mt={5} mb={2} spacing={2}>
        <Grid item xs={12} md={6}>
          {device < 2 ? (
            <ChipTitleComponent
              label="Solicitudes rechazadas"
              icon={<NoTransferIcon />}
            />
          ) : (
            <Typography variant="h5">
              Solicitudes rechazadas <NoTransferIcon color="warning" />
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6} textAlign="end">
          <TextField
            id="searchRechazo"
            type="text"
            placeholder="Buscar..."
            variant="outlined"
            aria-label="Search Input"
            size="small"
            value={filtro}
            autoComplete="false"
            onChange={(e) => setFiltro(e.target.value.trim())}
            onKeyDown={(e) => {
              if (e.key === "Enter") consulta(1, filtro);
            }}
            InputProps={{
              endAdornment: cargando ? (
                <IconButton size="small">
                  <CircularProgress color="warning" size={30} />
                </IconButton>
              ) : (
                <IconButton onClick={() => consulta(1, filtro)}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>

      <DataTable
        columns={columns}
        data={data}
        highlightOnHover
        pagination
        paginationServer
        paginationComponentOptions={{
          noRowsPerPage: true,
          rangeSeparatorText: "de",
        }}
        onChangePage={(_pag) => consulta(_pag, filtro)}
        expandableRows
        expandableRowsComponent={DetalleMovimiento}
        paginationTotalRows={total}
        paginationPerPage={10}
      />

      {seleccion && (
        <Dialog open={abre}>
          <DialogTitle>¿Seguro que desea restaurar la solicitud?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Con motivo de rechazo: {seleccion.MensajeRechazo}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setAbre(false)}>
              Cancelar
            </Button>
            <Button onClick={() => handleRestauraSolicitud()}>Ok</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
