import React, { useState } from "react";
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import FeedIcon from "@mui/icons-material/Feed";

import { guardaCache } from "../utils/cache/sessionStorage";
import { Colors } from "../styles";

export default function SideBarHomePage({
  device,
  userdata,
  modulo,
  setModulo,
  abreMenu,
  setAbreMenu,
  submodulo,
  setSubmodulo,
}) {
  const [abreMenuHist, setAbreMenuHist] = useState(false);
  const selectedColor = "#eef4fb";
  const anchoSidebar = 230;

  const historicoSubmodulos = [
    { nombre: "Operaciones", clave: "ops" },
    { nombre: "Operaciones por Hubs", clave: "ops-hubs" },
    { nombre: "Servicios en progreso", clave: "servicios" },
    { nombre: "Entregas", clave: "entregas" },
  ];

  const modulos = [
    {
      nombre: "Tracking",
      clave: "tracking",
      icono: <LineAxisIcon color={modulo === "tracking" ? "warning" : ""} />,
      visible: true,
    },
    {
      nombre: "Solicitudes",
      clave: "solicitudes",
      icono: (
        <LocalShippingIcon color={modulo === "solicitudes" ? "warning" : ""} />
      ),
      visible: userdata?.agente,
    },
    {
      nombre: "Presolicitudes",
      clave: "presolicitudes",
      icono: <FeedIcon color={modulo === "presolicitudes" ? "warning" : ""} />,
      visible: true,
    },
  ];

  const seleccionModulo = (_modulo) => {
    guardaCache("modulo", _modulo);
    setModulo(_modulo);
    setAbreMenu(false);
  };

  return (
    <Drawer
      anchor="left"
      open={device < 4 ? abreMenu : true}
      onClose={() => setAbreMenu(false)}
      variant={device < 4 ? "temporary" : "permanent"}
      sx={{
        width: anchoSidebar,
        flexShrink: 0,
        backgroundColor: Colors.darkgrey,
      }}
    >
      <Toolbar />
      <Box sx={{ width: anchoSidebar, overflow: "auto" }} role="presentation">
        <List>
          {modulos.map(
            (m) =>
              m.visible && (
                <ListItem
                  key={m.clave}
                  sx={{
                    padding: 0,
                    background: modulo === m.clave ? selectedColor : "white",
                  }}
                >
                  <ListItemButton
                    key={"b" + m.clave}
                    onClick={() => {
                      seleccionModulo(m.clave);
                      setAbreMenuHist(false);
                    }}
                  >
                    <ListItemIcon>{m.icono}</ListItemIcon>
                    <ListItemText primary={m.nombre} />
                  </ListItemButton>
                </ListItem>
              )
          )}

          <ListItem
            key={"dashboard"}
            sx={{
              padding: 0,
              background: modulo === "dashboard" ? selectedColor : "white",
            }}
          >
            <ListItemButton
              key={"dashboard"}
              onClick={() => {
                seleccionModulo("dashboard");
                setAbreMenuHist(!abreMenuHist);
              }}
            >
              <ListItemIcon>
                <EqualizerIcon
                  color={modulo === "dashboard" ? "warning" : ""}
                />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItemButton>
          </ListItem>

          <Collapse in={abreMenuHist} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {historicoSubmodulos.map((sub) => (
                <ListItemButton
                  key={sub.clave}
                  selected={submodulo === sub.clave}
                  sx={{ pl: 4 }}
                  onClick={() => {
                    setSubmodulo(sub.clave);
                    setAbreMenu(false);
                  }}
                >
                  <ListItemText secondary={sub.nombre} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
      </Box>
    </Drawer>
  );
}
