import urls from "../utils/constants/urls";
import { peticion } from "./peticionHttp";

async function getPresolicitudes(pag, filtro = "") {
  var url = `${urls.getPresolicitudes}?page=${pag}`;

  if (filtro !== "") url += "&busqueda=" + filtro;

  const res = await peticion(url, "GET");
  return res;
}

export { getPresolicitudes };
