import { obtieneCache } from "../utils/cache/sessionStorage";
import { decripta } from "../utils/utils";

async function peticion(url, metodo, body = null, esEncriptado = false) {
  // Prepara parametros
  const userdata = obtieneCache("userdata");
  const parametros = {
    method: metodo,
  };

  // Prepara headers
  const headers = {
    "Content-Type": "application/json",
  };
  if (userdata !== null) headers["Authorization"] = "Bearer " + userdata.token;

  parametros["headers"] = headers;

  // Si el cuerpo se manda encriptado o no
  if (metodo === "POST") {
    if (esEncriptado) {
      const encBody = decripta(body);
      parametros["body"] = JSON.stringify({ enc: encBody });
    } else {
      parametros["body"] = JSON.stringify(body);
    }
  }

  // Recibe respuesta
  try {
    const res = await fetch(url, parametros);
    if (res.ok) {
      const res_json = res.json();
      return res_json;
    } else {
      return { success: false, error: "Ups.. Hubo un error en la petición" };
    }
  } catch (error) {
    return { success: false, error: "Ups.. Hubo un error en la aplicación" };
  }
}

async function peticionArchivo(url, metodo, nombreArchivo) {
  // Prepara parametros
  const userdata = obtieneCache("userdata");
  const parametros = {
    method: metodo,
  };

  // Prepara headers
  const headers = {
    "Content-Type": "application/json",
  };
  if (userdata !== null) headers["Authorization"] = "Bearer " + userdata.token;

  parametros["headers"] = headers;

  // Recibe respuesta
  try {
    await fetch(url, parametros).then((respuesta) => {
      respuesta.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = nombreArchivo;
        a.click();
      });
    });
  } catch (error) {
    return { success: false, error: "Ups.. Hubo un error en la aplicación" };
  }
}

export { peticion, peticionArchivo };
