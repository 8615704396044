function guardaCache(key, objeto) {
  sessionStorage.setItem(key, JSON.stringify(objeto));
  return true;
}

function obtieneCache(key) {
  return JSON.parse(sessionStorage.getItem(key));
}

function borraCache(key) {
  return sessionStorage.removeItem(key);
}

export { guardaCache, obtieneCache, borraCache };
