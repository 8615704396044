import urls from "../utils/constants/urls";
import { peticion } from "./peticionHttp";

async function getPresolicitudesHistoria(pag, filtro = "") {
  var url = `${urls.getPresolicitudesHistoria}?page=${pag}`;

  if (filtro !== "") url += "&busqueda=" + filtro;

  const res = await peticion(url, "GET");
  return res;
}

export { getPresolicitudesHistoria };
