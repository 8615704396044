import urls from "../utils/constants/urls";
import { peticion } from "./peticionHttp";
import { guardaCache } from "../utils/cache/sessionStorage";
import { encripta } from "../utils/utils";

async function login(user, pass, rememberMe, notify) {
  // Determina si puede redirigir a home por: true/false
  const url = urls.login;
  const body = {
    username: user,
    password: pass,
    rememberMe: rememberMe,
  };

  const encriptado = encripta(JSON.stringify(body));
  const res = await peticion(url, "POST", encriptado, true);

  if (res.success) {
    // Guardar token
    guardaCache("userdata", res.info);

    // Guardar datos encrptados
    guardaCache("access", encriptado.toString());

    // Puede redirigir
    return true;
  } else {
    notify("warning", res.info);
    return false;
  }
}

export { login };
