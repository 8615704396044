const margin = {
  xl: 27,
  l: 20,
  m: 15,
  s: 10,
  xs: 5,
};

const padding = {
  xl: 24,
  l: 18,
  m: 12,
  s: 8,
  xs: 4,
};

const borderRadius = {
  s: "7px",
};

const centeredWindow = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
};

const centeredDiv = {
  position: "relative",
  left: "50%",
  top: "50%",
  width: "50%",
  height: "50%",
  margin: "0 auto",
  // transform: "translate(-50%, -50%)",
};

const raisedBox = {
  padding: padding.xs,
  boxShadow: "1px 5px 5px grey",
  border: "1px solid #e7cccc",
  borderRadius: 1,
};

export {
  margin,
  padding,
  borderRadius,
  centeredWindow,
  centeredDiv,
  raisedBox,
};
