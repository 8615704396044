import React, { useState } from "react";
import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DataTable from "react-data-table-component";
import HistoryIcon from "@mui/icons-material/History";
import SearchIcon from "@mui/icons-material/Search";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import AcUnitIcon from "@mui/icons-material/AcUnit";

import ChipTitleComponent from "./utils/ChipTitleComponent";
import {
  columnasPresolicitud,
  columnasPresolicitudMobile,
} from "../utils/utils";

export default function PresolicitudesMovimientos({
  device,
  data,
  consulta,
  total,
  cargando,
}) {
  const [filtro, setFiltro] = useState("");

  const columnsProcess = [
    {
      name: "Estatus",
      width: "115px",
      cell: (row) => (
        <Chip
          label={row.EstatusLabel.Nombre || ""}
          color={row.EstatusLabel.Color || ""}
          size="small"
        ></Chip>
      ),
    },
    ...(device < 4
      ? columnasPresolicitudMobile(null, [], device)
      : columnasPresolicitud()),
  ];

  const DetalleMovimiento = ({ data }) => {
    if (device < 4 || data.EstatusLabel.Nombre === "Rechazado")
      return (
        <List dense>
          {data.EstatusLabel.Nombre === "Rechazado" && (
            <ListItem>
              <ListItemText
                primary="Motivo del rechazo:"
                secondary={data.MensajeRechazo}
              />
            </ListItem>
          )}
          {device < 3 && (
            <ListItem>
              <Stack direction="row" spacing={1} mb={1}>
                {data.EsPeligroso && (
                  <>
                    <ReportGmailerrorredIcon />
                    <Typography variant="subtitle2">&nbsp;Peligroso</Typography>
                  </>
                )}
                {data.EsRefeer && (
                  <>
                    <AcUnitIcon />
                    <Typography variant="subtitle2">&nbsp;Refeer</Typography>
                  </>
                )}
                {data.EsSobredimension && (
                  <>
                    <SettingsOverscanIcon />
                    <Typography variant="subtitle2">
                      &nbsp;Sobredimensionado
                    </Typography>
                  </>
                )}
              </Stack>
            </ListItem>
          )}
          {device < 2 && <ListItem>BL: {data.Bl}</ListItem>}
          {device < 4 && (
            <>
              <ListItem>
                <ListItemText primary={"Tipo: " + data.Tipo}></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText primary={"Buque: " + data.Buque}></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText primary={"Viaje: " + data.Viaje}></ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={"ETA: " + (data.Eta || "SN")}
                ></ListItemText>
              </ListItem>
            </>
          )}
        </List>
      );
    else return null;
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center" mt={7} mb={2}>
        <Grid item xs={12} md={6}>
          {device < 2 ? (
            <ChipTitleComponent
              label="Historial de movimientos"
              icon={<HistoryIcon />}
            />
          ) : (
            <Typography variant="h5">
              Historial de movimientos <HistoryIcon color="warning" />
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6} textAlign="end">
          <TextField
            id="search"
            type="text"
            placeholder="Buscar..."
            variant="outlined"
            aria-label="Search Input"
            size="small"
            value={filtro}
            autoComplete="false"
            fullWidth={device < 3}
            onChange={(e) => setFiltro(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") consulta(1, filtro);
            }}
            InputProps={{
              endAdornment: cargando ? (
                <IconButton size="small">
                  <CircularProgress color="warning" size={30} />
                </IconButton>
              ) : (
                <IconButton onClick={() => consulta(1, filtro)}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>
      <DataTable
        columns={columnsProcess}
        data={data}
        highlightOnHover
        expandableRows
        expandableRowDisabled={(data) =>
          data.EstatusLabel.Nombre !== "Rechazado" && device > 3
        }
        expandableRowsComponent={DetalleMovimiento}
        pagination
        paginationServer
        paginationComponentOptions={{
          noRowsPerPage: true,
          rangeSeparatorText: "de",
        }}
        onChangePage={(_pag) => consulta(_pag, filtro)}
        paginationTotalRows={total}
        paginationPerPage={10}
      />
    </>
  );
}
