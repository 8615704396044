import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";

import { updateSolicitud } from "../services/updateSolicitud";
import {
  notify,
  columnasPresolicitud,
  tablaSeleccionCondicionObject,
  columnasPresolicitudMobile,
} from "../utils/utils";
import DialogRechazoCont from "./utils/DialogRechazoCont";
import * as XLSX from "xlsx";

export default function SolicitudesPendientes({
  device,
  data,
  handleRefresh,
  setSelectedClient,
  setSelectedCont,
  total,
  consulta,
  cargando,
  selected,
  setSelected,
  clearRows,
  esAgente,
}) {
  const [sending, setSending] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [rejectOpen, setRejectOpen] = useState(false);

  const disabledCreateOrder = () => {
    const sinHub = selected.filter((s) => s.Cliente.Hub === "");
    return sending || (sinHub.length === 0 && selected.length === 0);
  };

  const extraColumns = [
    {
      name: "Cliente",
      sortable: true,
      hide: "sm",
      selector: (row) => (
        <Tooltip
          title="Da clic para agregar hub id"
          style={{ cursor: "pointer" }}
        >
          <Typography
            variant="caption"
            color="#311b92"
            onClick={() => setSelectedClient(row.Cliente)}
          >
            {row.Cliente.Nombre}
          </Typography>
        </Tooltip>
      ),
    },
    {
      name: "Fecha solicitud",
      cell: (row) => (
        <Typography variant="caption" color="grey">
          {row.Solicitado}
        </Typography>
      ),
      sortable: false,
      hide: "sm",
    },
  ];

  const columns =
    device < 4
      ? columnasPresolicitudMobile(setSelectedCont, extraColumns, esAgente)
      : columnasPresolicitud(setSelectedCont, extraColumns, esAgente);

  const downloadFilter = () => {
    const filtro = data.map((f) => {
      return {
        Contenedor: f.Contenedor,
        Bl: f.Bl,
        Tipo: f.Tipo,
        Buque: f.Buque,
        Viaje: f.Viaje,
        Eta: f.Eta,
        Peligroso: f.EsPeligroso ? "x" : "",
        Refeer: f.EsRefeer ? "x" : "",
        Sobredimensionado: f.EsSobredimensionado ? "x" : "",
        Creado: f.Creado,
        Cliente: f.Cliente.Nombre,
        Solicitado: f.Solicitado,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(filtro);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
    XLSX.writeFile(workbook, "Solicitudes pendientes - Logipuerto.xlsx");
  };

  return (
    <>
      <Grid container spacing={1} my={2} alignItems="center">
        <Grid item xs={12} md={6} lg={6}>
          <Stack spacing={1} direction={device < 3 ? "column" : "row"}>
            <Button
              variant="contained"
              disabled={disabledCreateOrder()}
              onClick={async () => {
                setSending(true);
                const res = await updateSolicitud(
                  selected.map((s) => s.id),
                  "Autorizado"
                );

                setSending(false);
                if (res.exitoso) {
                  notify("success", res.msg);
                  handleRefresh();
                } else {
                  notify("error", res.error);
                }
              }}
            >
              Crear orden de servicio
            </Button>
            <Button
              variant="outlined"
              disabled={selected.length === 0}
              onClick={() => setRejectOpen(true)}
            >
              Rechazar
            </Button>
            <Button variant="text" color="success" onClick={downloadFilter}>
              <FileDownloadIcon htmlColor="green" />
            </Button>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          textAlign={device < 3 ? "left" : "end"}
        >
          <TextField
            id="search"
            type="text"
            placeholder="Buscar..."
            variant="outlined"
            aria-label="Search Input"
            size="small"
            value={filtro}
            autoComplete="false"
            onChange={(e) => setFiltro(e.target.value.trim())}
            onKeyDown={(e) => {
              if (e.key === "Enter") consulta(1, filtro);
            }}
            InputProps={{
              endAdornment: cargando ? (
                <IconButton size="small">
                  <CircularProgress color="warning" size={30} />
                </IconButton>
              ) : (
                <IconButton onClick={() => consulta(1, filtro)}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>

      <DataTable
        columns={columns}
        data={data}
        highlightOnHover
        selectableRows
        selectableRowsComponent={Checkbox}
        onSelectedRowsChange={({ selectedRows }) => {
          setSelected(selectedRows);
        }}
        clearSelectedRows={clearRows}
        conditionalRowStyles={tablaSeleccionCondicionObject(selected)}
        pagination
        paginationServer
        paginationComponentOptions={{
          noRowsPerPage: true,
          rangeSeparatorText: "de",
        }}
        onChangePage={(_pag) => consulta(_pag, filtro)}
        paginationTotalRows={total}
        paginationPerPage={10}
      />

      <DialogRechazoCont
        open={rejectOpen}
        setOpen={setRejectOpen}
        selected={selected}
        handleRefresh={handleRefresh}
        setSelected={setSelected}
      />
    </>
  );
}
