import { Box, Toolbar } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { obtieneCache } from "../utils/cache/sessionStorage";
import NavBarComponent from "./NavBarComponent";
import DeviceContext from "../utils/contexts/deviceContext";
import Tracking from "./Tracking";
import SideBarHomePage from "./SideBarHomePage";
import Presolicitudes from "./Presolicitudes";
import Solicitudes from "./Solicitudes";
import Historico from "./Historico";

export default function Inicio() {
  const navigate = useNavigate();
  const device = useContext(DeviceContext);
  const [userdata, setUserdata] = useState(null);

  const [modulo, setModulo] = useState("");
  const [submodulo, setSubmodulo] = useState("ops");
  const [abreMenu, setAbreMenu] = useState(false);

  useEffect(() => {
    const data = obtieneCache("userdata");

    if (!data) {
      navigate("/login");
    } else {
      try {
        setUserdata(data);
        const m = obtieneCache("modulo") || "tracking";
        setModulo(m);
      } catch {
        navigate("/login");
      }
    }
  }, [navigate]);

  return (
    <Box sx={{ display: "flex" }}>
      {userdata && (
        <>
          <NavBarComponent
            device={device}
            navigate={navigate}
            title=""
            userdata={userdata}
            handleAbreMenu={() => {
              setAbreMenu(!abreMenu);
            }}
          />
          <SideBarHomePage
            device={device}
            userdata={userdata}
            modulo={modulo}
            setModulo={setModulo}
            abreMenu={abreMenu}
            setAbreMenu={setAbreMenu}
            submodulo={submodulo}
            setSubmodulo={setSubmodulo}
          />
        </>
      )}

      <Box sx={{ flexGrow: 1, pl: 3, pt: 3, pr: 3 }}>
        <Toolbar />
        {modulo === "tracking" ? (
          <Tracking device={device} />
        ) : modulo === "solicitudes" ? (
          <Solicitudes device={device} />
        ) : modulo === "presolicitudes" ? (
          <Presolicitudes device={device} />
        ) : modulo === "dashboard" ? (
          <Historico device={device} submodulo={submodulo} />
        ) : null}
      </Box>
    </Box>
  );
}
