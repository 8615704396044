import {
  Button,
  Drawer,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { updateCliente } from "../services/updateCliente";
import { notify } from "../utils/utils";

export default function DrawerCliente({
  selectedClient,
  setSelectedClient,
  handleRefresh,
}) {
  const [hub, setHub] = useState(selectedClient.Hub);
  const [pending, setPending] = useState(false);

  const handleUpdate = async () => {
    setPending(true);
    const res = await updateCliente(selectedClient.id, hub);

    setPending(false);
    if (res.exitoso) {
      notify("success", "Cliente actualizado");
      setSelectedClient(null);
      handleRefresh();
    } else {
      notify("error", res.error);
    }
  };

  return (
    <Drawer
      anchor="right"
      onClose={() => setSelectedClient(null)}
      open={selectedClient !== null}
    >
      <Toolbar />
      <Typography variant="h5" mx={3} mt={4} mb={1}>
        {selectedClient.Nombre}
      </Typography>

      <Stack mx={2} mt={2} spacing={2}>
        <TextField
          id="outlined-basic"
          label="Hub id"
          variant="outlined"
          error={hub === ""}
          value={hub}
          inputProps={{ maxLength: 5 }}
          onChange={(e) => setHub(e.target.value)}
          placeholder="Ingrese un hub id"
        />
        <Button
          variant="contained"
          disabled={hub === "" || pending}
          onClick={handleUpdate}
        >
          Actualizar
        </Button>
      </Stack>
    </Drawer>
  );
}
