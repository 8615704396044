import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import UploadIcon from "@mui/icons-material/Upload";
import SearchIcon from "@mui/icons-material/Search";

import {
  notify,
  columnasPresolicitud,
  tablaSeleccionCondicion,
  columnasPresolicitudMobile,
} from "../utils/utils";
import { updateSolicitud } from "../services/updateSolicitud";
import { Colors } from "../styles";

export default function PresolicitudesPendientes({
  device,
  data,
  handleRefresh,
  openCreate,
  setOpenCreate,
  setSelectedContainer,
  consulta,
  total,
  cargando,
  selected,
  setSelected,
}) {
  const [pending, setPending] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [clearRows, setClearRows] = useState(false);
  const columns =
    device < 4
      ? columnasPresolicitudMobile(setSelectedContainer)
      : columnasPresolicitud(setSelectedContainer);

  const handleClearRows = () => {
    setSelected([]);
    setClearRows(!clearRows);
  };

  const handleUpdateSolicitud = async (status) => {
    setPending(true);
    const res = await updateSolicitud(selected, status);

    setPending(false);
    if (res.exitoso) {
      handleClearRows();
      notify("success", res.msg);
      handleRefresh();
    } else {
      notify(
        "error",
        "Ups.. hubo un error en la petición. Inténtelo otra vez."
      );
    }
  };

  return (
    <>
      <Grid container spacing={2} my={1} alignItems="center">
        <Grid item xs={12} md={8}>
          <Stack direction={device < 2 ? "column" : "row"} spacing={1}>
            {data.length > 0 && (
              <>
                <Button
                  variant="contained"
                  disabled={selected.length === 0 || pending}
                  onClick={() => handleUpdateSolicitud("Solicitud")}
                >
                  {pending ? (
                    <CircularProgress size={22} />
                  ) : (
                    <>Solicitar transporte</>
                  )}
                </Button>
                <Button
                  variant="outlined"
                  disabled={selected.length === 0}
                  onClick={() => handleUpdateSolicitud("Eliminado")}
                >
                  Eliminar
                </Button>
              </>
            )}
            <Tooltip
              title={
                <Box fontSize={15}>Da click para subir tus contenedores</Box>
              }
              placement={device < 2 ? "bottom" : "right"}
              open={data.length === 0 && !openCreate}
              arrow
            >
              <Button
                variant="contained"
                color="warning"
                onClick={() => setOpenCreate(true)}
              >
                <UploadIcon /> &nbsp;
                {device < 3 ? "Cargar" : "Cargar contenedores"}
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
        {data.length > 0 && (
          <Grid item xs={12} md={4} textAlign="end">
            <TextField
              id="search"
              type="text"
              placeholder="Buscar..."
              variant="outlined"
              aria-label="Search Input"
              size="small"
              fullWidth={device < 3}
              value={filtro}
              autoComplete="false"
              onChange={(e) => setFiltro(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") consulta(1, filtro);
              }}
              InputProps={{
                endAdornment: cargando ? (
                  <IconButton size="small">
                    <CircularProgress color="warning" size={30} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => consulta(1, filtro)}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        )}
      </Grid>

      <DataTable
        columns={columns}
        data={data}
        highlightOnHover
        selectableRows
        selectableRowsComponent={Checkbox}
        onSelectedRowsChange={({ selectedRows }) =>
          setSelected(selectedRows.map((s) => s.id))
        }
        clearSelectedRows={clearRows}
        conditionalRowStyles={tablaSeleccionCondicion(selected)}
        pagination
        paginationServer
        paginationComponentOptions={{
          noRowsPerPage: true,
          rangeSeparatorText: "de",
        }}
        onChangePage={(_pag) => consulta(_pag, filtro)}
        paginationTotalRows={total}
        paginationPerPage={10}
        noDataComponent={
          <Box py={8} textAlign="center">
            <Typography variant="subtitle1" color={Colors.grey} my={2}>
              No hay información para mostrar
            </Typography>
          </Box>
        }
      />
    </>
  );
}
