import {
  AppBar,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import MapComponent from "./MapComponent";
import CloseIcon from "@mui/icons-material/Close";
import { Colors, Layout } from "../../styles";
import { useState } from "react";
import { useEffect } from "react";
import { getTrackingInfo } from "../../services/getTrackingInfo";
import { Container } from "@mui/system";
import TimelineStepComponent from "./TimelineStepComponent";

export default function MapaTracking({ seleccion, cierraMapa, device }) {
  const [info, setInfo] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    (async () => {
      if (seleccion) {
        const res = await getTrackingInfo(seleccion.id);
        if (res.exitoso) {
          setInfo(res.data);
        }
      }
      setPending(false);
    })();
  }, [seleccion]);

  return (
    <>
      <AppBar sx={{ backgroundColor: Colors.darkgrey }} position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={cierraMapa}
            aria-label="close"
            style={{ position: "absolute", right: Layout.margin.l }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {pending ? (
        <Container sx={{ textAlign: "center", marginTop: 40 }}>
          <CircularProgress size={80} />
          <Typography variant="h5" mt={2}>
            Cargando...
          </Typography>
        </Container>
      ) : (
        <>
          <TimelineStepComponent
            device={device}
            etapas={info.etapas}
            ultimaEtapa={info.ultimaEtapa}
            orientation="horizontal"
          />
          <MapComponent url={info?.mapa} device={device} fullSize={true} />
        </>
      )}
    </>
  );
}
