import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { login } from "../services/login";
import { Colors, Layout } from "../styles";
import { decripta } from "../utils/utils";
import { obtieneCache } from "../utils/cache/sessionStorage";
import LogoImg from "../assets/logipuerto2.png";
import DeviceContext from "../utils/contexts/deviceContext";
import NotificationContext from "../utils/contexts/notificationContext";

export default function Login() {
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [recuerdame, setRecuerdame] = useState(true);
  const [cargando, setCargando] = useState(false);
  const navigate = useNavigate();
  const device = useContext(DeviceContext);
  const notify = useContext(NotificationContext);

  const loguearUsuario = async () => {
    if (usuario && password) {
      setCargando(true);
      const redirigir = await login(usuario, password, recuerdame, notify);
      setCargando(false);

      if (redirigir) navigate("/");
    } else {
      notify("info", "Ingrese usuario y contraseña");
    }
  };

  useEffect(() => {
    const userdata = obtieneCache("userdata");
    if (!userdata) {
      var acceso = obtieneCache("access");
      if (acceso) {
        const u = JSON.parse(decripta(acceso, true));

        // Si se marco "recuerdame", rellenar campos
        if (u.rememberMe) {
          setUsuario(u.username);
          setPassword(u.password);
        }
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Container
      sx={{
        ...Layout.centeredWindow,
        width: device < 2 ? "90%" : device < 3 ? "80%" : "50%",
        backgroundColor: "#f1f1f1",
        fontWeight: "light",
      }}
      maxWidth="sm"
    >
      <Stack
        sx={{
          ...Layout.raisedBox,
          alignItems: "center",
          backgroundColor: Colors.white,
        }}
      >
        <div>
          <img src={LogoImg} alt="Logo logipuerto" width="100%" />
        </div>
        <Typography
          display="block"
          // variant="h5"
          fontSize={20}
          mb={2}
          mt={1}
          color={Colors.grey}
        >
          Inicia sesión
        </Typography>
        <Stack
          spacing={1}
          ml={5}
          mr={5}
          mb={3}
          textAlign="center"
          width={device < 2 ? "80%" : "60%"}
        >
          <TextField
            id="user"
            label="Usuario"
            variant="outlined"
            size={device < 3 ? "small" : "medium"}
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") loguearUsuario();
            }}
          />
          <TextField
            id="pass"
            label="Contraseña"
            variant="outlined"
            value={password}
            size={device < 3 ? "small" : "medium"}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") loguearUsuario();
            }}
            type="password"
            autoComplete="current-password"
          />

          <FormControlLabel
            control={
              <Checkbox
                color="warning"
                checked={recuerdame}
                onChange={() => setRecuerdame(!recuerdame)}
              />
            }
            label={
              <Typography
                variant={device < 3 ? "caption" : "caption"}
                display="block"
                color={Colors.grey}
              >
                Recordarme
              </Typography>
            }
          />
          <Button
            fullWidth
            variant="contained"
            color="warning"
            disabled={cargando}
            sx={{ marginTop: 1 }}
            onClick={() => {
              loguearUsuario();
            }}
          >
            {cargando && <CircularProgress size={20} />} &nbsp; Entrar
          </Button>
          <Button href="http://www.logipuerto.mx">
            <Typography variant="caption">Regresar a inicio</Typography>
          </Button>
        </Stack>
        <Stack
          spacing={1}
          ml={5}
          mr={5}
          textAlign="center"
          width={device < 2 ? "80%" : "60%"}
        >
          <a
            style={{ textDecoration: "none" }}
            href="https://iscs.contecon.mx/apl/sist/sist-reco/recordatorio-de-contrasena/"
          >
            <Typography variant="caption">Olvidé mi contraseña</Typography>
          </a>
        </Stack>
      </Stack>
    </Container>
  );
}
