import {
  Badge,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import FeedIcon from "@mui/icons-material/Feed";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import { getTrackingSolicitudes } from "../services/getTrackingSolicitudes";
import MapaTracking from "./tracking/MapaTracking";
import CaractContenedor from "./utils/CaractContenedor";
import FormOrdenServicio from "./utils/FormOrdenServicio";
import { columnasTracking } from "../utils/utils";
import { obtieneCache } from "../utils/cache/sessionStorage";
import { Box } from "@mui/system";
import { Colors } from "../styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Tracking({ device }) {
  const [datos, setDatos] = useState([]);
  const [seleccion, setSeleccion] = useState(null);
  const [seleccionOrden, setSeleccionOrden] = useState(null);
  const [abre, setAbre] = useState(false);
  const [abreOrden, setAbreOrden] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [cargando, setCargando] = useState(false);
  // Paginacion
  const [total, setTotal] = useState(0);
  const userdata = obtieneCache("userdata");

  const cierraMapa = () => {
    setSeleccion(null);
    setAbre(false);
  };

  const seleccionaMapa = (row) => {
    setSeleccion(row);
    setAbre(true);
  };

  const columnas = columnasTracking(device, seleccionaMapa);

  const TrackingContenedor = ({ data }) => {
    return (
      <div style={{ padding: 20 }}>
        {device < 2 && (
          <List dense sx={{ paddingBottom: 0 }}>
            <ListItem>
              <CaractContenedor cont={data} />
            </ListItem>
            <ListItem>BL: {data.Bl}</ListItem>
          </List>
        )}
        <List dense sx={{ paddingTop: 0 }}>
          {data.Cancelado && (
            <ListItem>
              <Chip label="Servicio cancelado" color="warning" />
            </ListItem>
          )}
          <ListItem>Tipo: {data.Tipo}</ListItem>
          <ListItem>Buque: {data.Buque}</ListItem>
          <ListItem>Viaje: {data.Viaje}</ListItem>
          <ListItem>ETA: {data.Eta || "SN"}</ListItem>
        </List>
        {userdata.agente !== undefined && !data.Cancelado && (
          <Badge
            badgeContent={""}
            color="warning"
            variant="dot"
            invisible={data.Descripcion === null}
          >
            <Button
              color="info"
              variant="outlined"
              size="small"
              sx={{ marginLeft: 2 }}
              startIcon={<EditIcon />}
              onClick={() => {
                setAbreOrden(true);
                setSeleccionOrden(data);
              }}
            >
              Editar
            </Button>
          </Badge>
        )}
      </div>
    );
  };

  const consulta = async (_pag) => {
    setCargando(true);
    var res = await getTrackingSolicitudes(_pag, filtro);

    setDatos(res.results);
    setTotal(res.count);
    setCargando(false);
  };

  useEffect(() => {
    consulta(1);
  }, []);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Tracking
      </Typography>

      {datos.length > 0 && (
        <Grid container my={2}>
          <Grid item xs={12} textAlign={device < 2 ? "start" : "end"}>
            <TextField
              id="search"
              type="text"
              placeholder="Buscar..."
              variant="outlined"
              aria-label="Search Input"
              size="small"
              value={filtro}
              autoComplete="false"
              onChange={(e) => setFiltro(e.target.value.trim())}
              onKeyDown={(e) => {
                if (e.key === "Enter") consulta(1);
              }}
              InputProps={{
                endAdornment: cargando ? (
                  <IconButton size="small">
                    <CircularProgress color="warning" size={30} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => consulta(1)}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}

      <DataTable
        columns={columnas}
        data={datos}
        highlightOnHover
        expandableRows
        expandableRowsComponent={TrackingContenedor}
        pagination
        paginationServer
        paginationComponentOptions={{
          noRowsPerPage: true,
          rangeSeparatorText: "de",
        }}
        onChangePage={(_pag) => {
          consulta(_pag);
        }}
        paginationTotalRows={total}
        paginationPerPage={10}
        noDataComponent={
          <Box py={10} textAlign="center">
            <ManageSearchIcon style={{ fontSize: "80px", color: "gray" }} />
            <Typography
              variant={device < 3 ? "h5" : "h4"}
              my={2}
              color="#ea6b1f"
              fontWeight="bold"
            >
              No hay información para mostrar
            </Typography>
            <Typography variant="subtitle1" color={Colors.grey}>
              De click en{" "}
              <Chip
                variant="outlined"
                icon={<FeedIcon />}
                label="Presolicitudes"
              />{" "}
              y solicite su transporte.
            </Typography>
          </Box>
        }
      />

      <Dialog open={abreOrden} onClose={() => setAbreOrden(false)}>
        <FormOrdenServicio
          device={device}
          obj={seleccionOrden}
          setObj={setSeleccionOrden}
          datos={datos}
          setDatos={setDatos}
          setAbreOrden={setAbreOrden}
        />
      </Dialog>

      <Dialog
        open={abre}
        fullScreen
        onClose={cierraMapa}
        TransitionComponent={Transition}
      >
        {seleccion && abre && (
          <MapaTracking
            seleccion={seleccion}
            cierraMapa={cierraMapa}
            device={device}
          />
        )}
      </Dialog>
    </>
  );
}
