import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { notify } from "./utils/utils";
import DeviceContext from "./utils/contexts/deviceContext";
import Inicio from "./components/Inicio";
import Login from "./components/Login";
import NotificationContext from "./utils/contexts/notificationContext";

function App() {
  const [deviceKind, setDeviceKind] = useState(-1);

  const mobile = useMediaQuery({
    query: "(min-device-width: 425px)",
  });

  const tablet = useMediaQuery({
    query: "(min-device-width: 768px)",
  });

  const laptop = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });

  const desktop = useMediaQuery({
    query: "(min-device-width: 1440px)",
  });

  const bigScreen = useMediaQuery({
    query: "(min-device-width: 2560px )",
  });

  useEffect(() => {
    setDeviceKind(
      bigScreen ? 5 : desktop ? 4 : laptop ? 3 : tablet ? 2 : mobile ? 1 : 0
    );
  }, [mobile, tablet, laptop, desktop, bigScreen]);

  return (
    deviceKind !== -1 && (
      <NotificationContext.Provider value={notify}>
        <DeviceContext.Provider value={deviceKind}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Inicio />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </BrowserRouter>
          <ToastContainer limit={2} />
        </DeviceContext.Provider>
      </NotificationContext.Provider>
    )
  );
}

export default App;
