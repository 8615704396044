import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import NearMeDisabledIcon from "@mui/icons-material/NearMeDisabled";
import { Colors, Layout } from "../../styles";

const MapComponent = ({ url, device, fullSize = false }) => {
  const { innerHeight: height } = window;

  return (
    <div style={{ overflow: "hidden" }}>
      {url ? (
        <iframe
          id="iframe"
          title="iframe"
          seamless
          scrolling="no"
          style={{
            position: "relative",
            border: "1px solid " + Colors.lightgrey1,
            minHeight: height + 200,
            width: "99.9%",
            top: -105,
          }}
          src={url}
        ></iframe>
      ) : (
        <Alert
          severity="info"
          icon={<NearMeDisabledIcon />}
          sx={{
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          <AlertTitle>
            <strong>Ups..</strong>
          </AlertTitle>
          No se encontró servicio de tracking. Favor de contactar con su agente
          de logipuerto.
        </Alert>
      )}
    </div>
  );
};

export default MapComponent;
