import urls from "../utils/constants/urls";
import { peticion } from "./peticionHttp";

async function getTrackingSolicitudes(pag, filtro = "") {
  var url = `${urls.getTrackingSolicitudes}?page=${pag}`;

  if (filtro !== "") url += "&busqueda=" + filtro;

  const res = await peticion(url, "GET");
  return res;
}

export { getTrackingSolicitudes };
