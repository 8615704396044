import React, { useState } from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import LogoutIcon from "@mui/icons-material/Logout";
import DialogActions from "@mui/material/DialogActions";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, IconButton, Stack, Toolbar, Typography } from "@mui/material";

import { Colors } from "../styles";
import { borraCache } from "../utils/cache/sessionStorage";
import LogoImg from "../assets/logipuerto2.png";

export default function NavBarComponent({
  device,
  navigate,
  title,
  userdata,
  handleAbreMenu,
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: Colors.darkgrey, zIndex: 1201 }}
      >
        <Toolbar>
          <div
            style={{
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack direction="row">
              {device < 4 && (
                <IconButton onClick={() => handleAbreMenu()}>
                  <MenuIcon sx={{ color: "white" }} fontSize="medium" />
                </IconButton>
              )}
              {device > 1 && (
                <img alt="logo" src={LogoImg} width="140" height="65" />
              )}
            </Stack>
          </div>
          <div style={{ float: "right" }}>{userdata.usuario}</div>
          <div>
            <Tooltip title="Salir">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setOpen(true)}
                color="inherit"
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Está seguro que desea salir?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)} autoFocus>
            No
          </Button>
          <Button
            onClick={() => {
              borraCache("userdata");
              navigate("/login");
            }}
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
